<template>
  <div class="text-center logos">
    <img v-if="bounce" class="bounce-logo" src="../assets/bounce-logo.png" />
    <img
      v-if="wonderball"
      class="wonderball-logo"
      src="../assets/wonderball-logo.svg"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    bounce: {
      type: Boolean,
      default: true
    },
    wonderball: {
      type: Boolean,
      default: true
    }
  }
});
</script>

<style lang="postcss" scoped>
.logos {
  @apply pt-10 mr-10 ml-10;
}

.logos img {
  margin: 0px auto 15px auto;
  max-height: 100px;
}

.logos .wonderball-logo {
  max-height: 50px;
}
</style>
