
import { defineComponent, ref } from "vue";
import Player from "@/models/Player";
import { VueCropperMethods } from "vue-cropperjs";
import { MutationTypes } from "@/store/mutation-types";
import { v4 as uuid } from "uuid";
import Logos from "@/components/Logos.vue";

const Component = defineComponent({
  components: {
    Logos
  },
  data() {
    return {
      player: {} as Player,
      avatars: [require("@/assets/avatars/7.png")],
      hasError: false
    };
  },
  beforeMount() {
    this.player.photo = this.avatars[0];
  },
  setup() {
    const cropper = ref<VueCropperMethods | null>(null);
    const fileInput = ref<HTMLInputElement | null>(null);
    return {
      cropper,
      fileInput
    };
  },
  methods: {
    onPhotoChanged(event: Event) {
      const field = event.target as HTMLInputElement;
      if (!field.files || field.files.length < 1) return;
      const photo = field.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onload = () => this.updateCropImage(reader.result as string);
      reader.onerror = (event: ProgressEvent<FileReader>) =>
        this.handlePhotoError(event);
    },
    updateCropImage(photo: string) {
      this.cropper?.replace(photo);
    },
    handlePhotoError(event: ProgressEvent<FileReader>) {
      console.log(event);
    },
    addPlayer() {
      const player = this.player;
      this.hasError = false;

      if (!player.name) {
        this.hasError = true;
        return;
      }

      if (!player.uuid) {
        player.uuid = uuid();
      }

      if (this.cropper) {
        player.photo = this.cropper
          .getCroppedCanvas({
            width: 500,
            height: 500,
            minWidth: 500,
            minHeight: 500
          })
          .toDataURL();
      }

      this.$store.commit(MutationTypes.ADD_PLAYER, player);
      this.$router.push({ name: "players" });
    },
    takePhoto() {
      this.fileInput?.click();
    }
  }
});

export default Component;
