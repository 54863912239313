import AddPlayer from "@/views/AddPlayer.vue";
import Home from "@/views/Home.vue";
import Players from "@/views/Players.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:token/",
    name: "home",
    component: Home,
  },
  {
    path: "/:token/players",
    name: "players",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Players,
  },
  {
    path: "/:token/players/add",
    name: "add-player",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AddPlayer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
