
import { MutationTypes } from "@/store/mutation-types";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import TableService from "@/service/TableService";
import Logos from "@/components/Logos.vue";
import axios from "axios";

export default defineComponent({
  components: {
    Logos
  },
  data() {
    return {
      submitting: false,
      finished: false,
      error: ""
    };
  },
  computed: mapState(["players"]),
  methods: {
    async saveTable() {
      const token = this.$route.params.token as string;
      const players = this.$store.state.players;
      this.submitting = true;
      try {
        await TableService.addPlayers(token, players);
      } catch (e) {
        if (
          axios.isAxiosError(e) &&
          e.response &&
          e.response.data &&
          e.response.data.message
        ) {
          this.error = e.response.data.message;
        } else {
          this.error = "Couldn't save players, please try again";
        }
        this.submitting = false;
        return;
      }
      this.$store.commit(MutationTypes.DELETE_ALL_PLAYERS);
      this.submitting = false;
      this.finished = true;
    }
  }
});
