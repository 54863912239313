import Player from "@/models/Player";
import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [MutationTypes.ADD_PLAYER](state: S, player: Player): void;
  [MutationTypes.DELETE_PLAYER](state: S, player: Player): void;
  [MutationTypes.DELETE_ALL_PLAYERS](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.ADD_PLAYER](state, player) {
    state.players.push(player);
  },
  [MutationTypes.DELETE_PLAYER](state, player) {
    const index = state.players.indexOf(player);
    if (index > -1) state.players.splice(index, 1);
  },
  [MutationTypes.DELETE_ALL_PLAYERS](state) {
    state.players = [];
  },
};
