import Player from "@/models/Player";
import axios from "axios";

class TableService {
  constructor() {
    axios.defaults.baseURL = "https://wonderballapi.bouncepingpong.com";
  }

  async addPlayers(token: string, players: Player[]) {
    const data = new FormData();
    const json = JSON.stringify(
      players.map((p) => {
        return {
          name: p.name,
          image: `${p.uuid}.png`,
        };
      })
    );

    data.append("players", json);

    for await (const player of players) {
      const response = await fetch(player.photo);
      const blob = await response.blob();
      data.append("files[]", blob, `${player.uuid}.png`);
    }

    await axios.post("players", data, {
      headers: {
        Token: token,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async reset(token: string) {
    await axios.get("reset", {
      headers: {
        Token: token,
      },
    });
  }
}

export default new TableService();
