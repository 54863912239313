import "cropperjs/dist/cropper.css";
import { createApp } from "vue";
import VueCropper from "vue-cropperjs";
import App from "./App.vue";
import "./assets/index.css";
import router from "./router";
import { store } from "./store";

createApp(App)
  .use(store)
  .use(router)
  .component("VueCropper", VueCropper)
  .mount("#app");
